// triggered by parent frame
import "iframe-resizer/js/iframeResizer.contentWindow";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { MinimalApp } from "./renderer/MinimalApp";
import { theme } from "./theme/theme";

const cacheNoPrefixer = createCache({
  key: "noprefixer",
  ...(process.env.NODE_ENV === "development" && { stylisPlugins: [] }),
});

export const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <div className="App">
      <CacheProvider value={cacheNoPrefixer}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <MinimalApp />
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
