// generated with @7nohe/openapi-react-query-codegen@1.6.1
import { UseQueryResult } from "@tanstack/react-query";

import { ConfigurationService } from "../requests/services.gen";

export type ConfigurationServiceGetInfoDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getInfo>
>;
export type ConfigurationServiceGetInfoQueryResult<
  TData = ConfigurationServiceGetInfoDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetInfoKey = "ConfigurationServiceGetInfo";
export const UseConfigurationServiceGetInfoKeyFn = (
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetInfoKey, ...(queryKey ?? [])];
export type ConfigurationServiceGetStatisticsDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getStatistics>
>;
export type ConfigurationServiceGetStatisticsQueryResult<
  TData = ConfigurationServiceGetStatisticsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetStatisticsKey =
  "ConfigurationServiceGetStatistics";
export const UseConfigurationServiceGetStatisticsKeyFn = (
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetStatisticsKey, ...(queryKey ?? [])];
export type ConfigurationServiceGetHealthDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getHealth>
>;
export type ConfigurationServiceGetHealthQueryResult<
  TData = ConfigurationServiceGetHealthDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetHealthKey =
  "ConfigurationServiceGetHealth";
export const UseConfigurationServiceGetHealthKeyFn = (
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetHealthKey, ...(queryKey ?? [])];
export type ConfigurationServiceGetLocalesDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getLocales>
>;
export type ConfigurationServiceGetLocalesQueryResult<
  TData = ConfigurationServiceGetLocalesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetLocalesKey =
  "ConfigurationServiceGetLocales";
export const UseConfigurationServiceGetLocalesKeyFn = (
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetLocalesKey, ...(queryKey ?? [{ releaseId }])];
export type ConfigurationServiceGetUnitsDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getUnits>
>;
export type ConfigurationServiceGetUnitsQueryResult<
  TData = ConfigurationServiceGetUnitsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetUnitsKey =
  "ConfigurationServiceGetUnits";
export const UseConfigurationServiceGetUnitsKeyFn = (
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetUnitsKey, ...(queryKey ?? [{ releaseId }])];
export type ConfigurationServiceGetCurrenciesDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getCurrencies>
>;
export type ConfigurationServiceGetCurrenciesQueryResult<
  TData = ConfigurationServiceGetCurrenciesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetCurrenciesKey =
  "ConfigurationServiceGetCurrencies";
export const UseConfigurationServiceGetCurrenciesKeyFn = (
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetCurrenciesKey,
  ...(queryKey ?? [{ releaseId }]),
];
export type ConfigurationServiceGetThemesDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getThemes>
>;
export type ConfigurationServiceGetThemesQueryResult<
  TData = ConfigurationServiceGetThemesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetThemesKey =
  "ConfigurationServiceGetThemes";
export const UseConfigurationServiceGetThemesKeyFn = (
  {
    releaseId,
    themeId,
  }: {
    releaseId?: string;
    themeId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetThemesKey,
  ...(queryKey ?? [{ releaseId, themeId }]),
];
export type ConfigurationServiceGetLayoutsDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getLayouts>
>;
export type ConfigurationServiceGetLayoutsQueryResult<
  TData = ConfigurationServiceGetLayoutsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetLayoutsKey =
  "ConfigurationServiceGetLayouts";
export const UseConfigurationServiceGetLayoutsKeyFn = (
  {
    cfgObjectId,
    layoutId,
    releaseId,
    rootCfgObjectId,
  }: {
    cfgObjectId: string;
    layoutId?: string;
    releaseId?: string;
    rootCfgObjectId: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetLayoutsKey,
  ...(queryKey ?? [{ cfgObjectId, layoutId, releaseId, rootCfgObjectId }]),
];
export type ConfigurationServiceGetStructuredTranslationsDefaultResponse =
  Awaited<ReturnType<typeof ConfigurationService.getStructuredTranslations>>;
export type ConfigurationServiceGetStructuredTranslationsQueryResult<
  TData = ConfigurationServiceGetStructuredTranslationsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetStructuredTranslationsKey =
  "ConfigurationServiceGetStructuredTranslations";
export const UseConfigurationServiceGetStructuredTranslationsKeyFn = (
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetStructuredTranslationsKey,
  ...(queryKey ?? [{ localeId, releaseId }]),
];
export type ConfigurationServiceGetAssetDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getAsset>
>;
export type ConfigurationServiceGetAssetQueryResult<
  TData = ConfigurationServiceGetAssetDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetAssetKey =
  "ConfigurationServiceGetAsset";
export const UseConfigurationServiceGetAssetKeyFn = (
  {
    path,
  }: {
    path: string;
  },
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetAssetKey, ...(queryKey ?? [{ path }])];
export type ConfigurationServiceGetConfiguratorsDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getConfigurators>
>;
export type ConfigurationServiceGetConfiguratorsQueryResult<
  TData = ConfigurationServiceGetConfiguratorsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetConfiguratorsKey =
  "ConfigurationServiceGetConfigurators";
export const UseConfigurationServiceGetConfiguratorsKeyFn = (
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetConfiguratorsKey,
  ...(queryKey ?? [{ localeId, releaseId }]),
];
export type ConfigurationServiceGetLogsDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getLogs>
>;
export type ConfigurationServiceGetLogsQueryResult<
  TData = ConfigurationServiceGetLogsDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetLogsKey = "ConfigurationServiceGetLogs";
export const UseConfigurationServiceGetLogsKeyFn = (
  {
    id,
  }: {
    id: string;
  },
  queryKey?: Array<unknown>,
) => [useConfigurationServiceGetLogsKey, ...(queryKey ?? [{ id }])];
export type ConfigurationServiceSaveConfigurationDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.saveConfiguration>
>;
export type ConfigurationServiceSaveConfigurationQueryResult<
  TData = ConfigurationServiceSaveConfigurationDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceSaveConfigurationKey =
  "ConfigurationServiceSaveConfiguration";
export const UseConfigurationServiceSaveConfigurationKeyFn = (
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceSaveConfigurationKey,
  ...(queryKey ?? [{ currencyId, id, localeId }]),
];
export type ConfigurationServiceCheckUpgradeConfigurationDefaultResponse =
  Awaited<ReturnType<typeof ConfigurationService.checkUpgradeConfiguration>>;
export type ConfigurationServiceCheckUpgradeConfigurationQueryResult<
  TData = ConfigurationServiceCheckUpgradeConfigurationDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceCheckUpgradeConfigurationKey =
  "ConfigurationServiceCheckUpgradeConfiguration";
export const UseConfigurationServiceCheckUpgradeConfigurationKeyFn = (
  {
    id,
  }: {
    id: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceCheckUpgradeConfigurationKey,
  ...(queryKey ?? [{ id }]),
];
export type ConfigurationServiceGetBomDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getBom>
>;
export type ConfigurationServiceGetBomQueryResult<
  TData = ConfigurationServiceGetBomDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetBomKey = "ConfigurationServiceGetBom";
export const UseConfigurationServiceGetBomKeyFn = (
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetBomKey,
  ...(queryKey ?? [{ currencyId, id, localeId }]),
];
export type ConfigurationServiceGetSummaryDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getSummary>
>;
export type ConfigurationServiceGetSummaryQueryResult<
  TData = ConfigurationServiceGetSummaryDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetSummaryKey =
  "ConfigurationServiceGetSummary";
export const UseConfigurationServiceGetSummaryKeyFn = (
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetSummaryKey,
  ...(queryKey ?? [{ currencyId, id, localeId }]),
];
export type ConfigurationServiceGetAssemblyDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getAssembly>
>;
export type ConfigurationServiceGetAssemblyQueryResult<
  TData = ConfigurationServiceGetAssemblyDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetAssemblyKey =
  "ConfigurationServiceGetAssembly";
export const UseConfigurationServiceGetAssemblyKeyFn = (
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetAssemblyKey,
  ...(queryKey ?? [{ currencyId, id, localeId }]),
];
export type ConfigurationServiceGetDrawingDefaultResponse = Awaited<
  ReturnType<typeof ConfigurationService.getDrawing>
>;
export type ConfigurationServiceGetDrawingQueryResult<
  TData = ConfigurationServiceGetDrawingDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetDrawingKey =
  "ConfigurationServiceGetDrawing";
export const UseConfigurationServiceGetDrawingKeyFn = (
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: Array<unknown>,
) => [
  useConfigurationServiceGetDrawingKey,
  ...(queryKey ?? [{ currencyId, id, localeId }]),
];
export type ConfigurationServiceReloadMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.reload>
>;
export type ConfigurationServiceCreateConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.createConfiguration>
>;
export type ConfigurationServiceGetConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.getConfiguration>
>;
export type ConfigurationServiceCloneConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.cloneConfiguration>
>;
export type ConfigurationServiceKeepConfigurationAliveMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.keepConfigurationAlive>
>;
export type ConfigurationServiceAddSubConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.addSubConfiguration>
>;
export type ConfigurationServiceCommitConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.commitConfiguration>
>;
export type ConfigurationServiceRollbackConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.rollbackConfiguration>
>;
export type ConfigurationServiceGetTableMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.getTable>
>;
export type ConfigurationServiceInvokeUserDefinedFunctionMutationResult =
  Awaited<ReturnType<typeof ConfigurationService.invokeUserDefinedFunction>>;
export type ConfigurationServiceInvokeUserDefinedGetterMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.invokeUserDefinedGetter>
>;
export type ConfigurationServiceLoadConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.loadConfiguration>
>;
export type ConfigurationServiceChangeConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.changeConfiguration>
>;
export type ConfigurationServiceResetConfigurationAttributesMutationResult =
  Awaited<ReturnType<typeof ConfigurationService.resetConfigurationAttributes>>;
export type ConfigurationServiceResetConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.resetConfiguration>
>;
export type ConfigurationServiceChangeSubConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.changeSubConfiguration>
>;
export type ConfigurationServiceUpgradeConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.upgradeConfiguration>
>;
export type ConfigurationServiceDeleteConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.deleteConfiguration>
>;
export type ConfigurationServiceDeleteSubConfigurationMutationResult = Awaited<
  ReturnType<typeof ConfigurationService.deleteSubConfiguration>
>;
