import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

import { ZimmerArrowIcon, ZimmerCheckIcon } from "../icons/ZimmerIcons";

import "./fonts.css";

export const customPalette = {
  background: {
    default: "#f3f3f3",
  },
  primary: {
    main: "rgb(0, 134, 205)",
    dark: "#006da7",
    light: "#0086cd",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#707070",
    dark: "#3C3C3B",
    light: "#bfbfbf",
    contrastText: "#d5d5d5",
  },
  info: {
    main: "#007F7B",
    dark: "#169691",
  },
} satisfies PaletteOptions;

const customTheme = createTheme({
  palette: customPalette,
  typography: {
    fontFamily: "Zimmer Group Sans",
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          ".thumbnail-card": {
            maxWidth: "100%",
            marginInline: "1rem",
            paddingLeft: "0",
            ".MuiFormControl-root": {
              width: "100%",
            },
            ".active": {
              border: `1px solid ${customPalette.primary.main}`,
            },
          },
          "&.wwr_img": {
            img: {
              width: "auto",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          cursor: "default",
          "&:not(.MuiInputBase-multiline)": {
            height: "36px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: "17px",
          backgroundColor: customPalette.primary.main,
          color: customPalette.primary.contrastText,
          ".MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
              transform: "rotate(-180deg)",
            },
            "&::before": {
              fontFamily: "ZimmerIcons",
              rotate: "90deg",
              content: '"\uE900"',
              color: customPalette.primary.contrastText,
              fontSize: "20px",
            },
          },
          ".MuiSvgIcon-root": {
            display: "none",
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          marginRight: "10px",
          ".MuiSvgIcon-root": {
            transition: "none",
            borderRadius: "50%",
            border: `1px solid ${customPalette.secondary.light}`,
            color: "transparent",
          },
          "&.Mui-checked": {
            ".MuiSvgIcon-root": {
              color: customPalette.primary.contrastText,
              backgroundColor: customPalette.primary.main,
              borderColor: customPalette.primary.main,
            },
          },
          "&.Mui-disabled": {
            ".MuiSvgIcon-root": {
              color: "transparent",
              backgroundColor: customPalette.secondary.contrastText,
              borderColor: customPalette.secondary.light,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: customPalette.primary.contrastText,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: customPalette.secondary.contrastText,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${customPalette.primary.main}`,
          },
        },
        notchedOutline: {
          borderColor: customPalette.secondary.contrastText,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        IconComponent: (props) => {
          return <ZimmerArrowIcon {...props} />;
        },
      },
      styleOverrides: {
        select: {
          cursor: "default",
          p: {
            color: "inherit",
          },
        },
        icon: {
          transform: "rotate(90deg)",
          transition: "0.4s",
          marginRight: "15px",
          top: "inherit",
          right: "0",
          color: customPalette.primary.main,
          fontSize: "15px",
          cursor: "default",
        },
        iconOpen: {
          transition: "0.4s",
          transform: "rotate(-90deg)",
        },
        root: {
          legend: {
            display: "none",
          },
          ".MuiOutlinedInput-notchedOutline": {
            top: "0px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: customPalette.primary.main,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: "375px",
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: customPalette.primary.contrastText,
          border: `1px solid ${customPalette.primary.main}`,
          borderTop: "0px solid transparent",
          ".MuiButtonBase-root": {
            cursor: "default",
            borderBlock: "1px solid transparent",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected": {
              backgroundColor: `${customPalette.primary.contrastText}!important`,
            },
            "&:hover": {
              color: customPalette.primary.main,
              backgroundColor: "transparent",
              borderBlockColor: customPalette.secondary.light,
              "&:first-of-type": {
                borderTopColor: "transparent",
              },
              "&:last-child": {
                // borderBottomColor: customPalette.primary.main,
                borderBottomColor: "transparent",
              },
              "&.Mui-selected": {
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <ZimmerCheckIcon />,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "22px",
          height: "22px",
          fontSize: "11px",
          marginRight: "10px",
          borderRadius: "3px",
          border: `1px solid ${customPalette.secondary.light}`,
          backgroundColor: customPalette.primary.contrastText,
          color: customPalette.primary.contrastText,
          "&.Mui-checked": {
            span: {
              color: customPalette.primary.contrastText,
            },
            "&.MuiButtonBase-root": {
              borderColor: customPalette.primary.main,
              backgroundColor: customPalette.primary.main,
            },
          },
          "&.Mui-disabled": {
            span: {
              color: customPalette.secondary.contrastText,
            },
            "&.MuiButtonBase-root": {
              borderColor: customPalette.secondary.light,
              backgroundColor: customPalette.secondary.contrastText,
              color: customPalette.secondary.contrastText,
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            fontWeight: "bold",
          },
          ".MuiTableRow-root": {
            verticalAlign: "top",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          hover: false,
          "&.Mui-selected": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          img: {
            display: "block",
            height: "90px",
            marginInline: "auto",
          },
          a: {
            textDecoration: "none",
            span: {
              whiteSpace: "nowrap",
            },
            "&:hover": {
              textDecoration: "underline",
            },
          },
          span: {
            display: "flex",
          },
          padding: "0 3px",
          height: "100px",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: customPalette.primary.main,
          ".MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            ".MuiTableSortLabel-icon": {
              color: customPalette.primary.main,
            },
          },
          "&:hover": {
            color: "inherit",
          },
        },
        icon: {
          color: customPalette.primary.main,
          alignSelf: "start",
          marginRight: "10px",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        disableAutoFocus: true,
      },
      styleOverrides: {
        paper: {
          "&:not(.MuiMenu-paper)": {
            maxWidth: "250px",
            margin: "0px",
            boxShadow: "2px 2px 5px 0px rgba(0, 0, 0, 0.2)",
            ".MuiBox-root": {
              margin: "0px",
              padding: "0.25rem 0.5rem",
              p: {
                margin: "0px",
              },
            },
          },
          boxShadow: "none",
        },
        root: {
          color: "red",
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          height: "40px",
          fontSize: "13px",
          fontWeight: "700",
          boxShadow: "none",
          border: `1px solid ${customPalette.primary.main}`,
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: customPalette.primary.contrastText,
            color: customPalette.primary.main,
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            backgroundColor: customPalette.primary.main,
            color: customPalette.primary.contrastText,
            opacity: "0.65",
            cursor: "not-allowed",
          },
          "&.previous-disabled": {
            backgroundColor: "transparent",
            borderColor: "transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-9px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ".ValueContent-value": {
            p: {
              color: customPalette.primary.main,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000",
          fontWeight: 700,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
          marginBottom: "0.5rem",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          width: "50px",
          color: "transparent",
          height: "50px",
          borderRadius: "50%",
          borderTop: `6px solid ${customPalette.primary.contrastText}`,
          borderRight: `6px solid ${customPalette.primary.contrastText}`,
          borderBottom: `6px solid ${customPalette.primary.contrastText}`,
          borderLeft: `6px solid ${customPalette.primary.main}`,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: "3",
          backgroundColor: `rgba(243, 243, 243, 0.5)`,
          animation: "0.2s",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          ".MuiInputBase-root": {
            display: "none",
          },
        },
        selectLabel: {
          display: "none",
        },
        actions: {
          display: "flex",
          gap: "75px",
          marginLeft: 0,
          ".MuiIconButton-root": {
            height: "33px",
            backgroundColor: customPalette.primary.main,
            color: customPalette.primary.contrastText,
            borderRadius: "3px",
            border: `1px solid ${customPalette.primary.main}`,
            "&:hover": {
              color: customPalette.primary.main,
              backgroundColor: customPalette.primary.contrastText,
              borderColor: customPalette.primary.main,
            },
            "&.Mui-disabled": {
              color: "transparent",
              backgroundColor: "transparent",
              borderColor: "transparent",
            },
          },
        },
        displayedRows: {
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          width: "30px",
          left: "calc(50% - 15px)",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0px!important",
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
