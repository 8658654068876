// generated with @7nohe/openapi-react-query-codegen@1.6.1
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { ConfigurationService } from "../requests/services.gen";
import {
  AddSubConfigurationParams,
  ChangeConfigurationParams,
  ChangeSubConfigurationParams,
  CloneConfigurationParams,
  ConfigurationTableParams,
  CreateConfigurationParams,
  DeleteSubConfigurationParams,
  GetConfigurationParams,
  InvokeUserDefinedFunctionParams,
  InvokeUserDefinedGetterParams,
  LoadConfigurationParams,
  ResetConfigurationAttributesParams,
  ResetConfigurationParams,
  UpgradeConfigurationParams,
} from "../requests/types.gen";

import * as Common from "./common";

/**
 * Get API information of the configuration server.
 * @returns VersionInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetInfo = <
  TData = Common.ConfigurationServiceGetInfoDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetInfoKeyFn(queryKey),
    queryFn: () => ConfigurationService.getInfo() as TData,
    ...options,
  });
/**
 * Get load statistics of the configuration server.
 * @returns StatisticsInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetStatistics = <
  TData = Common.ConfigurationServiceGetStatisticsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetStatisticsKeyFn(queryKey),
    queryFn: () => ConfigurationService.getStatistics() as TData,
    ...options,
  });
/**
 * Get the health status of the configuration server.
 * @returns HealthInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetHealth = <
  TData = Common.ConfigurationServiceGetHealthDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetHealthKeyFn(queryKey),
    queryFn: () => ConfigurationService.getHealth() as TData,
    ...options,
  });
/**
 * Get a list of supported locales for configuration.
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @returns LocaleInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetLocales = <
  TData = Common.ConfigurationServiceGetLocalesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLocalesKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getLocales({ releaseId }) as TData,
    ...options,
  });
/**
 * Get all unit systems. This includes all units, unit groups and dimensions.
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @returns ConfigurationUnits OK
 * @throws ApiError
 */
export const useConfigurationServiceGetUnits = <
  TData = Common.ConfigurationServiceGetUnitsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetUnitsKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getUnits({ releaseId }) as TData,
    ...options,
  });
/**
 * Get a list of supported currencies for pricing and costs.
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @returns CurrencyInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetCurrencies = <
  TData = Common.ConfigurationServiceGetCurrenciesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
  }: {
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetCurrenciesKeyFn(
      { releaseId },
      queryKey,
    ),
    queryFn: () => ConfigurationService.getCurrencies({ releaseId }) as TData,
    ...options,
  });
/**
 * Get the theme definition.
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @param data.themeId The theme ID.
 * @returns ThemeInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetThemes = <
  TData = Common.ConfigurationServiceGetThemesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    releaseId,
    themeId,
  }: {
    releaseId?: string;
    themeId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetThemesKeyFn(
      { releaseId, themeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getThemes({ releaseId, themeId }) as TData,
    ...options,
  });
/**
 * Get the layout definition to render a specific configuration object.
 * @param data The data for the request.
 * @param data.rootCfgObjectId The root configuration object ident (ID).
 * @param data.cfgObjectId The configuration object for which to receive the layout information.
 * @param data.releaseId The configurator ID.
 * @param data.layoutId The ID of the layout.
 * @returns LayoutInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetLayouts = <
  TData = Common.ConfigurationServiceGetLayoutsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    cfgObjectId,
    layoutId,
    releaseId,
    rootCfgObjectId,
  }: {
    cfgObjectId: string;
    layoutId?: string;
    releaseId?: string;
    rootCfgObjectId: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLayoutsKeyFn(
      { cfgObjectId, layoutId, releaseId, rootCfgObjectId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getLayouts({
        cfgObjectId,
        layoutId,
        releaseId,
        rootCfgObjectId,
      }) as TData,
    ...options,
  });
/**
 * Get structured translations for a specific configuration object or all configuration objects.
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @param data.localeId The locale ID (e.g. en-US). If omitted or if the locale is not supported, the fallback translations will be returned.
 * @returns DictionaryEntry OK
 * @throws ApiError
 */
export const useConfigurationServiceGetStructuredTranslations = <
  TData = Common.ConfigurationServiceGetStructuredTranslationsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetStructuredTranslationsKeyFn(
      { localeId, releaseId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getStructuredTranslations({
        localeId,
        releaseId,
      }) as TData,
    ...options,
  });
/**
 * Get a static asset (e.g. image or PDF).
 * @param data The data for the request.
 * @param data.path The path of the asset.
 * @returns unknown OK
 * @throws ApiError
 */
export const useConfigurationServiceGetAsset = <
  TData = Common.ConfigurationServiceGetAssetDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    path,
  }: {
    path: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetAssetKeyFn({ path }, queryKey),
    queryFn: () => ConfigurationService.getAsset({ path }) as TData,
    ...options,
  });
/**
 * Get a list of all independent configuration objects (all objects available for configuration by the user).
 * @param data The data for the request.
 * @param data.releaseId The configurator ID.
 * @param data.localeId The locale ID (RFC 4646, e.g. en-US).
 * @returns ConfigurationObjectInfo OK
 * @throws ApiError
 */
export const useConfigurationServiceGetConfigurators = <
  TData = Common.ConfigurationServiceGetConfiguratorsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    localeId,
    releaseId,
  }: {
    localeId?: string;
    releaseId?: string;
  } = {},
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetConfiguratorsKeyFn(
      { localeId, releaseId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getConfigurators({ localeId, releaseId }) as TData,
    ...options,
  });
/**
 * Receive logs for the configuration (Web Socket or rest polling endpoint).
 * Logging must be enabled for the configuration and development mode must be enabled for the server.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @returns ConfigurationLogEvent OK
 * @throws ApiError
 */
export const useConfigurationServiceGetLogs = <
  TData = Common.ConfigurationServiceGetLogsDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetLogsKeyFn({ id }, queryKey),
    queryFn: () => ConfigurationService.getLogs({ id }) as TData,
    ...options,
  });
/**
 * Save the configuration as JSON.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.localeId The currency ID (ISO 4217, e.g. USD).
 * @param data.currencyId The path of the configuration object.
 * @returns ConfigurationSave OK
 * @throws ApiError
 */
export const useConfigurationServiceSaveConfiguration = <
  TData = Common.ConfigurationServiceSaveConfigurationDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceSaveConfigurationKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.saveConfiguration({
        currencyId,
        id,
        localeId,
      }) as TData,
    ...options,
  });
/**
 * Check if an upgrade for configuration is available.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @returns boolean OK
 * @throws ApiError
 */
export const useConfigurationServiceCheckUpgradeConfiguration = <
  TData = Common.ConfigurationServiceCheckUpgradeConfigurationDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceCheckUpgradeConfigurationKeyFn(
      { id },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.checkUpgradeConfiguration({ id }) as TData,
    ...options,
  });
/**
 * Get the configuration BOM.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.localeId The currency ID (ISO 4217, e.g. USD).
 * @param data.currencyId The path of the configuration object.
 * @returns ConfigurationBom OK
 * @throws ApiError
 */
export const useConfigurationServiceGetBom = <
  TData = Common.ConfigurationServiceGetBomDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetBomKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getBom({ currencyId, id, localeId }) as TData,
    ...options,
  });
/**
 * Get the configuration summary.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.localeId The currency ID (ISO 4217, e.g. USD).
 * @param data.currencyId The path of the configuration object.
 * @returns ConfigurationSummary OK
 * @throws ApiError
 */
export const useConfigurationServiceGetSummary = <
  TData = Common.ConfigurationServiceGetSummaryDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetSummaryKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getSummary({ currencyId, id, localeId }) as TData,
    ...options,
  });
/**
 * Get the configuration assembly (2D or 3D).
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.localeId The currency ID (ISO 4217, e.g. USD).
 * @param data.currencyId The path of the configuration object.
 * @returns ConfigurationAssembly OK
 * @throws ApiError
 */
export const useConfigurationServiceGetAssembly = <
  TData = Common.ConfigurationServiceGetAssemblyDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetAssemblyKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getAssembly({ currencyId, id, localeId }) as TData,
    ...options,
  });
/**
 * Get the configuration drawing (2D or 3D).
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.localeId The currency ID (ISO 4217, e.g. USD).
 * @param data.currencyId The path of the configuration object.
 * @returns ConfigurationDrawing OK
 * @throws ApiError
 */
export const useConfigurationServiceGetDrawing = <
  TData = Common.ConfigurationServiceGetDrawingDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    currencyId,
    id,
    localeId,
  }: {
    currencyId?: string;
    id: string;
    localeId?: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseConfigurationServiceGetDrawingKeyFn(
      { currencyId, id, localeId },
      queryKey,
    ),
    queryFn: () =>
      ConfigurationService.getDrawing({ currencyId, id, localeId }) as TData,
    ...options,
  });
/**
 * Fetch a new version of the KB and reload the configurator, only new configurations will be affected.
 * @returns unknown OK
 * @throws ApiError
 */
export const useConfigurationServiceReload = <
  TData = Common.ConfigurationServiceReloadMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<TData, TError, void, TContext>,
    "mutationFn"
  >,
) =>
  useMutation<TData, TError, void, TContext>({
    mutationFn: () =>
      ConfigurationService.reload() as unknown as Promise<TData>,
    ...options,
  });
/**
 * Create a new configuration.
 * @param data The data for the request.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceCreateConfiguration = <
  TData = Common.ConfigurationServiceCreateConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody: CreateConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody: CreateConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      ConfigurationService.createConfiguration({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Get (GET over POST) configuration objects of an existing configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceGetConfiguration = <
  TData = Common.ConfigurationServiceGetConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.getConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Create a new configuration as a clone of the current configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceCloneConfiguration = <
  TData = Common.ConfigurationServiceCloneConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: CloneConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: CloneConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.cloneConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Prevent the configuration from beeing garbage collected over time.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @returns unknown OK
 * @throws ApiError
 */
export const useConfigurationServiceKeepConfigurationAlive = <
  TData = Common.ConfigurationServiceKeepConfigurationAliveMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      ConfigurationService.keepConfigurationAlive({
        id,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Create a new sub-configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceAddSubConfiguration = <
  TData = Common.ConfigurationServiceAddSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: AddSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: AddSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.addSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Commits the configuration changes.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceCommitConfiguration = <
  TData = Common.ConfigurationServiceCommitConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.commitConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Rollback the configuration to the last commit.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceRollbackConfiguration = <
  TData = Common.ConfigurationServiceRollbackConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: GetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: GetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.rollbackConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Get a table of the given configuration object by path.
 * The getter must not have any side effects.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns ConfigurationTable OK
 * @throws ApiError
 */
export const useConfigurationServiceGetTable = <
  TData = Common.ConfigurationServiceGetTableMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ConfigurationTableParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ConfigurationTableParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.getTable({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Invoke a user defined function in as specific configuration object.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.name The name of the user defined function.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceInvokeUserDefinedFunction = <
  TData = Common.ConfigurationServiceInvokeUserDefinedFunctionMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        name: string;
        requestBody: InvokeUserDefinedFunctionParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      name: string;
      requestBody: InvokeUserDefinedFunctionParams;
    },
    TContext
  >({
    mutationFn: ({ id, name, requestBody }) =>
      ConfigurationService.invokeUserDefinedFunction({
        id,
        name,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Invoke a user defined getter in as specific configuration object.
 * The getter must not have any side effects.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.name The name of the user defined getter.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceInvokeUserDefinedGetter = <
  TData = Common.ConfigurationServiceInvokeUserDefinedGetterMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        name: string;
        requestBody: InvokeUserDefinedGetterParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      name: string;
      requestBody: InvokeUserDefinedGetterParams;
    },
    TContext
  >({
    mutationFn: ({ id, name, requestBody }) =>
      ConfigurationService.invokeUserDefinedGetter({
        id,
        name,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Restores a saved configuration from JSON.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceLoadConfiguration = <
  TData = Common.ConfigurationServiceLoadConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: LoadConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: LoadConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.loadConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Changes an existing configuration by selecting values and/or value quantifies.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceChangeConfiguration = <
  TData = Common.ConfigurationServiceChangeConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ChangeConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ChangeConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.changeConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Reset a list of attributes to their default value.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceResetConfigurationAttributes = <
  TData = Common.ConfigurationServiceResetConfigurationAttributesMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ResetConfigurationAttributesParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ResetConfigurationAttributesParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.resetConfigurationAttributes({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Reset the configuration or a sub-configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceResetConfiguration = <
  TData = Common.ConfigurationServiceResetConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ResetConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ResetConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.resetConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Copy or move a sub-configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceChangeSubConfiguration = <
  TData = Common.ConfigurationServiceChangeSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: ChangeSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: ChangeSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.changeSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Upgrade the configuration to the latest version.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceUpgradeConfiguration = <
  TData = Common.ConfigurationServiceUpgradeConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: UpgradeConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: UpgradeConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.upgradeConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Delete a configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @returns unknown OK
 * @throws ApiError
 */
export const useConfigurationServiceDeleteConfiguration = <
  TData = Common.ConfigurationServiceDeleteConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
    },
    TContext
  >({
    mutationFn: ({ id }) =>
      ConfigurationService.deleteConfiguration({
        id,
      }) as unknown as Promise<TData>,
    ...options,
  });
/**
 * Delete a sub-configuration.
 * @param data The data for the request.
 * @param data.id The configuration ID.
 * @param data.requestBody
 * @returns Configuration OK
 * @throws ApiError
 */
export const useConfigurationServiceDeleteSubConfiguration = <
  TData = Common.ConfigurationServiceDeleteSubConfigurationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        id: string;
        requestBody: DeleteSubConfigurationParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      id: string;
      requestBody: DeleteSubConfigurationParams;
    },
    TContext
  >({
    mutationFn: ({ id, requestBody }) =>
      ConfigurationService.deleteSubConfiguration({
        id,
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
